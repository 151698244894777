import React from "react";
import '../App.css';
import { fetchRegru } from "../props";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ChangeUser } from "../redux/actions/ScreenAction";

export const MarathonScreen = ({ marathon, uid, userMarathon, user }) => {
    const dispatch = useDispatch();
    const referral = useSelector(state => state.screen.referral);
    const [sub, setSubs] = React.useState(false);
    const [firstName, setFirstName] = React.useState(user.firstName);
    const [secondName, setSecondName] = React.useState(user.lastName);
    const [discount, setDiscount] = React.useState(false);

    React.useEffect(() => {
        if (uid) {
            fetchRegru('sub/check?id=' + uid, 'GET')
                .then((res) => {
                    if (res.status === 'success') {
                        setSubs(res.data);
                    } else {
                        window.alert('Проверка подписок прошла неудачно попробуйте перезагрузить страницу');
                    }
                })
                .catch(() => window.alert('Не смогли связаться с сервером'))
        }
    }, [uid]);

    React.useEffect(() => {
        const item = marathon.find(item => new Date(item.startDate).getTime() > new Date().getTime());
        fetchRegru('referals?id=' + item._id, 'GET')
            .then(res => {
                if (Array.isArray(res)) {
                    res.forEach(item => {
                        if (item.identificator === referral) {
                            setDiscount(true);
                        }
                    })
                }
            })
        let bought = user.marathons.find(m => m?.id === item?._id);
        if (bought) {
            if (window.confirm('Осталось только перейти в закрытый чат')) {
                window.location.href = item.telegramUrl
            }
        }
    }, [marathon, user, referral]);

    return (
        <div
            style={{
                flex: 1,
                overflow: 'scroll'
            }}
            className="screen-view"
        >
            <div
                style={{
                    overflow: 'scroll'
                }}
            >
                {
                    Array.isArray(userMarathon) ?
                        marathon?.map((item, index) => {
                            const startDate = new Date(item.startDate).getTime();
                            let bought = user.marathons.find(m => m.id === item._id);
                            if (startDate < new Date().getTime()) {
                                return null
                            }
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignSelf: 'center',
                                        marginTop: 20,
                                        marginBottom: 10,
                                        paddingBottom: 120
                                    }}
                                    key={index.toString()}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                                        <div
                                            style={{
                                                color: '#D89143',
                                                marginLeft: 16,
                                                marginRight: 16,
                                                fontFamily: 'Oswald',
                                                fontSize: 20
                                            }}
                                        >
                                            {item.title}
                                        </div>

                                        <div
                                            className="back-button"
                                            onClick={async () => {
                                                const res = window.confirm('Вы уверены, что хотите выйти?');
                                                if (res) {
                                                    dispatch(ChangeUser(undefined));
                                                    setTimeout(() => {
                                                        window.location.reload();
                                                    }, 200);
                                                }
                                            }}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.75 0H1.25C0.918479 0 0.600537 0.131696 0.366117 0.366117C0.131696 0.600537 0 0.918479 0 1.25L0 3.75C0 4.08152 0.131696 4.39946 0.366117 4.63388C0.600537 4.8683 0.918479 5 1.25 5C1.58152 5 1.89946 4.8683 2.13388 4.63388C2.3683 4.39946 2.5 4.08152 2.5 3.75V2.5H17.5V17.5H2.5V16.25C2.5 15.9185 2.3683 15.6005 2.13388 15.3661C1.89946 15.1317 1.58152 15 1.25 15C0.918479 15 0.600537 15.1317 0.366117 15.3661C0.131696 15.6005 0 15.9185 0 16.25L0 18.75C0 19.0815 0.131696 19.3995 0.366117 19.6339C0.600537 19.8683 0.918479 20 1.25 20H18.75C19.0815 20 19.3995 19.8683 19.6339 19.6339C19.8683 19.3995 20 19.0815 20 18.75V1.25C20 0.918479 19.8683 0.600537 19.6339 0.366117C19.3995 0.131696 19.0815 0 18.75 0V0Z" fill="url(#paint0_linear_19_16)" />
                                                <path d="M5.58999 6.39998C5.39057 6.29315 5.16588 6.24249 4.93991 6.25341C4.71394 6.26433 4.49518 6.33642 4.30699 6.46198L0.556988 8.95998C0.385791 9.07413 0.245422 9.22878 0.148338 9.41021C0.0512538 9.59163 0.000457764 9.79421 0.000457764 9.99998C0.000457764 10.2057 0.0512538 10.4083 0.148338 10.5898C0.245422 10.7712 0.385791 10.9258 0.556988 11.04L4.30699 13.54C4.49523 13.6654 4.71397 13.7373 4.93989 13.7482C5.16582 13.7591 5.39046 13.7085 5.58987 13.6017C5.78929 13.495 5.956 13.3361 6.07225 13.1421C6.1885 12.9481 6.24993 12.7262 6.24999 12.5V11.25H13.75C14.0815 11.25 14.3995 11.1183 14.6339 10.8839C14.8683 10.6494 15 10.3315 15 9.99998C15 9.66846 14.8683 9.35052 14.6339 9.1161C14.3995 8.88168 14.0815 8.74998 13.75 8.74998H6.24999V7.49998C6.24963 7.2741 6.18807 7.05255 6.07186 6.85886C5.95564 6.66517 5.78912 6.50659 5.58999 6.39998Z" fill="url(#paint1_linear_19_16)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_19_16" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#D89143" />
                                                        <stop offset="1" stopColor="#E4AC5F" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_19_16" x1="7.50022" y1="6.25195" x2="7.50022" y2="13.7497" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#D89143" />
                                                        <stop offset="1" stopColor="#E4AC5F" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>

                                    </div>
                                    <div
                                        style={{
                                            marginTop: 20,
                                            aspectRatio: 17 / 9,
                                            overflow: 'hidden',
                                            backgroundImage: `url(${item.imageUrl})`
                                        }}
                                        className='marathonimg'
                                    >
                                    </div>
                                    <div
                                        style={{
                                            color: 'white',
                                            marginLeft: 16,
                                            marginTop: 16,
                                            marginRight: 16,
                                            fontFamily: 'Oswald',
                                            whiteSpace: 'pre-line'
                                        }}
                                    >
                                        {item.description}
                                    </div>
                                    <input
                                        className="textInput"
                                        onChange={(val) => {
                                            setFirstName(val.target.value);
                                        }}
                                        placeholder='Имя'
                                        value={firstName}
                                    />
                                    <input
                                        className="textInput"
                                        onChange={(val) => {
                                            setSecondName(val.target.value);
                                        }}
                                        placeholder='Фамилия'
                                        value={secondName}
                                    />
                                    <div
                                        style={{
                                            color: 'white',
                                            fontSize: 16,
                                            margin: 16
                                        }}
                                    >
                                        {sub ? null : '*в стоимость входит подписка на тренера 299 руб/месяц'}
                                    </div>
                                    <button
                                        className="orange-button"
                                        // disabled={saleStartDate > new Date().getTime() || loading}
                                        onClick={async () => {
                                            if (!firstName) {
                                                return window.alert('Заполните поле имени')
                                            }
                                            if (!bought) {
                                                await fetchRegru('post/updateuser?id=' + uid, 'POST', {
                                                    firstName: firstName,
                                                    lastName: secondName,
                                                })
                                                const widget = new window.cp.CloudPayments()
                                                widget.pay(
                                                    'charge',
                                                    { //options
                                                        publicId: 'pk_4766ff888132870993fa2c870f349', //id из личного кабинета
                                                        description: item.title, //назначение
                                                        amount: Number(item.price) + (sub ? 0 : 299) - (discount ? 0 : 0), //сумма
                                                        currency: 'RUB', //валюта
                                                        accountId: uid, //идентификатор плательщика (необязательно)
                                                        requireEmail: true,
                                                        skin: 'modern',
                                                        autoClose: 3,
                                                        payer: {
                                                            firstName: firstName,
                                                            lastName: secondName,
                                                            phone: user.phoneNumber
                                                        },
                                                        data: {
                                                            referredBy: referral,
                                                            Phone: user.phoneNumber
                                                        }
                                                    },
                                                    {
                                                        onSuccess: async function (options) { // success
                                                            const item = marathon[0];
                                                            await axios.post('https://sheet.best/api/sheets/63c760c9-d9b8-4f7e-b247-353c6f6804f9', {
                                                                statusPokupki: 'куплено',
                                                                FI: firstName + ' ' + secondName,
                                                                id: uid,
                                                                phoneNumber: user.phoneNumber,
                                                                date: moment().format('MMMM Do YYYY, h:mm'),
                                                                refferedBy: referral
                                                            })
                                                                .catch(e => { })
                                                            fetchRegru('post/addmarathoninfo?id=' + uid, 'POST', {
                                                                id: item._id,
                                                                marathonType: 'coach',
                                                                withSub: true,
                                                                refferedBy: referral
                                                            })
                                                                .then(() => {
                                                                    window.open(item.telegramUrl)
                                                                    window.location.href = 'https://app.bodylineapp.com/marathon'
                                                                })
                                                                .catch(() => { })
                                                            //действие при успешной оплате
                                                        },
                                                        onFail: function (reason, options) { // fail
                                                            axios.post('https://sheet.best/api/sheets/63c760c9-d9b8-4f7e-b247-353c6f6804f9', {
                                                                statusPokupki: reason,
                                                                FI: firstName + ' ' + secondName,
                                                                id: uid,
                                                                phoneNumber: user.phoneNumber,
                                                                date: moment().format('MMMM Do YYYY, h:mm'),
                                                                refferedBy: referral
                                                            })
                                                            //действие при неуспешной оплате
                                                        },
                                                        onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                                                            //например вызов вашей аналитики
                                                        }
                                                    }
                                                )
                                            } else if (item.telegramUrl) {
                                                window.open(item.telegramUrl)
                                            } else {
                                                let userAgent = window.navigator.userAgent.replace(';', ' ').replace('(', ' ').replace(')', ' ')
                                                const arr = userAgent.split(' ');
                                                let type = '';
                                                arr.forEach((item, index) => {
                                                    if (item === 'Android') {
                                                        type = 'Android'
                                                        window.open("https://play.google.com/store/apps/details?id=com.bodyline", "_blank")
                                                    }
                                                    if (item === 'iPhone') {
                                                        type = 'iPhone'
                                                        window.open("https://apps.apple.com/kg/app/bodylineykt/id1502373306", "_blank")
                                                    }
                                                    if (item === 'iPad') {
                                                        type = 'iPad'
                                                        window.open("https://apps.apple.com/kg/app/bodylineykt/id1502373306", "_blank")
                                                    }
                                                })
                                                if (type === '') {
                                                    window.open("https://bodylineykt.ru/", "_blank")
                                                }
                                            }
                                        }}
                                    >
                                        {!bought ? <div>{'КУПИТЬ ЗА '}<s>{1499}</s>{` ${Number(item.price) + (sub ? 0 : 299)} ₽`}</div> : item.telegramUrl ? 'ПЕРЕЙТИ В ЧАТ' : 'ПЕРЕЙТИ В ПРИЛОЖЕНИЕ'}
                                    </button>
                                </div>
                            )
                        })
                        :
                        <div style={{ color: 'orange', fontFamily: 'Oswald', alignSelf: 'center', textAlign: 'center' }}>
                            ЗАГРУЗКА...
                        </div>
                }
            </div>
        </div>
    );
}