import React from "react";
import '../App.css';
import { useNavigate} from 'react-router-dom';
import InputMask from 'react-input-mask';
// import firebase from 'firebase/compat/app';
import { fetchRegru, mainColor } from "../props";
import { ChangeUser } from "../redux/actions/ScreenAction";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

// const axios = require('axios').default;

export const PhoneAuth = () => {
    const navigate = useNavigate();
    const phoneRef = React.useRef();
    const [phone, setPhone] = React.useState('+7');
    const [code, setCode] = React.useState('');
    const [load, setLoad] = React.useState(false);
    const [valid, setValid] = React.useState(false);
    const [timer, setTimer] = React.useState(60);
    const [count ,setCount] = React.useState(0);
    const [visible, setVisble] = React.useState(false);
    const dispatch = useDispatch();
    const captchaRef = React.useRef(null);

    React.useEffect(() => {
        // fetchRegru('get/user?id=6307b2077d32bdade1e1482b')
        // .then(res => {
        //     dispatch(ChangeUser({...res.data, uid: '6307b2077d32bdade1e1482b'}))
        // })
        let n = 0;
        let flag = true;
        window.setInterval(() => {
            n = n + 1;
            if (flag) {
                setCount(n);
            }
        },1000);

        return () => {flag = false};
    },[]);

    React.useEffect(() => {
        if (timer > 0) {
            setTimer(timer-1);
        }
    },[count])

    const Call = async (token) => {
        const formatted = phone.replaceAll(' ','').replaceAll('-','').replaceAll('(','').replaceAll(')','').replaceAll('+','').replaceAll('_','');
        setLoad(true);
        fetchRegru('phoneverification', 'POST', {phone: formatted, token: token})
        .then(res => {
            if (res.status === 'success' && res.data.status === 'OK') {
                setTimer(60);
                setValid(true);
            } else {
                window.alert(res.status+res.data?.status_text)
            }
        })
        .catch(e => {
            window.alert(e.message)
        })
        .finally(() => {
            setLoad(false);
        })
    }

    return(
        <div>
            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    width: '100%',
                    height: visible ? '100%' : 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden'
                }}
            >
                <ReCAPTCHA
                    sitekey={'6LfBL-YiAAAAADo8PFaYCO8yahV-v2uTRqjHm3B_'}
                    theme={'dark'}
                    size={'normal'}
                    ref={captchaRef}
                    asyncScriptOnLoad={() => {
                        
                    }}
                    onExpired={() => {
                        console.log('expired')
                        captchaRef.current.reset()
                    }}
                    
                    onChange={token => {
                        Call(token);
                        captchaRef.current.reset();
                        setVisble(false);
                    }}
                    onErrored={e => {
                        console.log('captcha error',e);
                    }}
                />
            </div>
            {
                valid?
                <div>
                    <div className="header">
                        <div className="back-button" onClick={() => {
                            setValid(false);
                        }}>
                            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.73876 2.31597L3.17076 6.88397L7.73976 11.453C7.84723 11.5601 7.93252 11.6873 7.99076 11.8274C8.049 11.9675 8.07905 12.1177 8.07918 12.2694C8.07931 12.4211 8.04952 12.5714 7.99153 12.7115C7.93353 12.8517 7.84846 12.9791 7.74118 13.0864C7.6339 13.1937 7.50652 13.2787 7.36633 13.3367C7.22614 13.3947 7.07589 13.4245 6.92418 13.4244C6.77246 13.4243 6.62226 13.3942 6.48218 13.336C6.34209 13.2777 6.21486 13.1924 6.10776 13.085L0.722763 7.69997C0.506347 7.48356 0.384766 7.19003 0.384766 6.88397C0.384766 6.57791 0.506347 6.28439 0.722763 6.06797L6.10776 0.683972C6.32595 0.476476 6.6166 0.362477 6.91768 0.366304C7.21876 0.370132 7.50642 0.491484 7.71926 0.70446C7.93211 0.917436 8.05328 1.20517 8.05693 1.50625C8.06057 1.80733 7.94639 2.09791 7.73876 2.31597Z" fill="white"/>
                            </svg>
                        </div>
                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <svg width="109" height="28" viewBox="0 0 109 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.4648 20.8929V7.10594C15.4648 2.86394 17.6998 0.439941 21.7898 0.439941C25.8798 0.439941 28.1148 2.86394 28.1148 7.10594V20.8929C28.1148 25.1349 25.8808 27.5589 21.7898 27.5589C17.6988 27.5589 15.4648 25.1349 15.4648 20.8929ZM19.6298 6.84094V21.1579C19.6298 23.0519 20.4628 23.7709 21.7888 23.7709C23.1148 23.7709 23.9478 23.0509 23.9478 21.1579V6.84094C23.9478 4.94694 23.1148 4.22794 21.7888 4.22794C20.4628 4.22794 19.6298 4.94694 19.6298 6.84094ZM49.5888 27.2569V18.4699L44.3298 0.740944H48.6858L51.8668 12.8229L55.0478 0.740944H59.0248L53.7608 18.4669V27.2539L49.5888 27.2569ZM89.6228 27.2569L84.4338 8.05694V27.2569H80.6838V0.740944H85.9108L90.1908 16.6099V0.740944H93.9028V27.2539L89.6228 27.2569ZM73.5228 27.2569V0.740944H77.6888V27.2539L73.5228 27.2569ZM30.7988 27.2569V0.740944H37.3888C41.5558 0.740944 43.5998 3.05094 43.5998 7.29294V20.7009C43.5998 24.9429 41.5548 27.2539 37.3888 27.2539L30.7988 27.2569ZM34.9648 23.4689H37.3128C38.6388 23.4689 39.4338 22.7869 39.4338 20.8929V7.10594C39.4338 5.21194 38.6338 4.52994 37.3128 4.52994H34.9648V23.4689ZM96.8918 27.2569V0.740944H108.254V4.52794H101.054V11.9139H106.774V15.7019H101.054V23.4659H108.254V27.2539L96.8918 27.2569ZM60.6828 27.2569V0.740944H64.8488V23.4659H71.7048V27.2529L60.6828 27.2569ZM0.424805 27.2569V0.740944H6.71179C11.0298 0.740944 12.8848 2.74794 12.8848 6.84094V7.78794C12.8848 10.5149 12.0518 12.2569 10.1958 13.1289C12.4298 13.9999 13.3008 16.0079 13.3008 18.8099V20.9689C13.3008 25.0599 11.1418 27.2569 6.97583 27.2569H0.424805ZM4.58978 23.4689H6.97583C8.37583 23.4689 9.13483 22.8249 9.13483 20.8559V18.5409C9.13483 16.0789 8.3348 15.3219 6.4458 15.3219H4.58978V23.4689ZM4.58978 11.5379H6.21881C7.77181 11.5379 8.71881 10.8559 8.71881 8.73794V7.26094C8.71881 5.36694 8.07384 4.53394 6.59784 4.53394H4.58978V11.5379Z" fill="url(#paint0_linear_1_7919)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1_7919" x1="0.424805" y1="0.439941" x2="13.2542" y2="51.4514" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#D89143"/>
                                        <stop offset="1" stopColor="#E4AC5F"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div style={{width: 50}}/>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            flexDirection: 'column',
                            color: 'white'
                        }}
                    >
                        <input 
                            className="textInput"
                            onChange={(event) => {
                                setCode(event.currentTarget.value.replace(/[^0-9]/g,''));
                            }}
                            style={{
                                marginTop: 70
                            }}
                            maxLength={4}
                            value={code}
                            placeholder='Код'
                        />
                        <div
                            style={{
                                color: '#7A7772',
                                margin: 30,
                                fontSize: 16
                            }}
                        >
                            Введите код из смс
                        </div>
                        <button
                            className="orange-button"
                            onClick={async () => {
                                const formatted = phone.replaceAll(' ','').replaceAll('-','').replaceAll('(','').replaceAll(')','').replaceAll('+','').replaceAll('_','');
                                setLoad(true);
                                fetchRegru('check/user', 'POST', {
                                    provider: 'phoneNumber',
                                    login: '+'+formatted,
                                    code: code,
                                    create: true
                                })
                                .then(res => {
                                    if (res.status === 'success') {
                                        if (res.message === 'new') {
                                            dispatch(ChangeUser({uid: 'new', phoneNumber: '+'+formatted}))
                                        } else {
                                            dispatch(ChangeUser({...res.data, uid: res.data._id}))
                                        }
                                        setLoad(false);
                                        window.location.reload()
                                    } else {
                                        window.alert('что-то пошло не так: \n'+res.message)
                                        setLoad(false);
                                    }
                                })
                                .catch((err) => {
                                    setLoad(false);
                                    window.alert(err.message);
                                })
                            }}
                            style={{
                                marginBottom: 0,
                                marginTop: 0
                            }}
                            disabled={load}
                        >
                            ПОДТВЕРДИТЬ
                        </button>
                        <div
                            style={{
                                color: '#7A7772',
                                margin: 30,
                                fontSize: 16
                            }}
                        >   
                            {
                                timer === 0? 
                                <button
                                    style={{
                                        backgroundColor: 'black',
                                        color: mainColor,
                                        outlineWidth: 0,
                                        borderWidth: 0,
                                        fontSize: 16
                                    }}
                                    onClick={async () => {
                                        setVisble(true);
                                    }}
                                >
                                    Повторить отправку
                                </button>
                                : 
                                `Повторить отправку можно через ${timer} секунд(у)`
                            }
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className="header">
                        <div className="back-button" onClick={() => {
                            navigate('/');
                        }}>
                            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.73876 2.31597L3.17076 6.88397L7.73976 11.453C7.84723 11.5601 7.93252 11.6873 7.99076 11.8274C8.049 11.9675 8.07905 12.1177 8.07918 12.2694C8.07931 12.4211 8.04952 12.5714 7.99153 12.7115C7.93353 12.8517 7.84846 12.9791 7.74118 13.0864C7.6339 13.1937 7.50652 13.2787 7.36633 13.3367C7.22614 13.3947 7.07589 13.4245 6.92418 13.4244C6.77246 13.4243 6.62226 13.3942 6.48218 13.336C6.34209 13.2777 6.21486 13.1924 6.10776 13.085L0.722763 7.69997C0.506347 7.48356 0.384766 7.19003 0.384766 6.88397C0.384766 6.57791 0.506347 6.28439 0.722763 6.06797L6.10776 0.683972C6.32595 0.476476 6.6166 0.362477 6.91768 0.366304C7.21876 0.370132 7.50642 0.491484 7.71926 0.70446C7.93211 0.917436 8.05328 1.20517 8.05693 1.50625C8.06057 1.80733 7.94639 2.09791 7.73876 2.31597Z" fill="white"/>
                            </svg>
                        </div>
                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <svg width="109" height="28" viewBox="0 0 109 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.4648 20.8929V7.10594C15.4648 2.86394 17.6998 0.439941 21.7898 0.439941C25.8798 0.439941 28.1148 2.86394 28.1148 7.10594V20.8929C28.1148 25.1349 25.8808 27.5589 21.7898 27.5589C17.6988 27.5589 15.4648 25.1349 15.4648 20.8929ZM19.6298 6.84094V21.1579C19.6298 23.0519 20.4628 23.7709 21.7888 23.7709C23.1148 23.7709 23.9478 23.0509 23.9478 21.1579V6.84094C23.9478 4.94694 23.1148 4.22794 21.7888 4.22794C20.4628 4.22794 19.6298 4.94694 19.6298 6.84094ZM49.5888 27.2569V18.4699L44.3298 0.740944H48.6858L51.8668 12.8229L55.0478 0.740944H59.0248L53.7608 18.4669V27.2539L49.5888 27.2569ZM89.6228 27.2569L84.4338 8.05694V27.2569H80.6838V0.740944H85.9108L90.1908 16.6099V0.740944H93.9028V27.2539L89.6228 27.2569ZM73.5228 27.2569V0.740944H77.6888V27.2539L73.5228 27.2569ZM30.7988 27.2569V0.740944H37.3888C41.5558 0.740944 43.5998 3.05094 43.5998 7.29294V20.7009C43.5998 24.9429 41.5548 27.2539 37.3888 27.2539L30.7988 27.2569ZM34.9648 23.4689H37.3128C38.6388 23.4689 39.4338 22.7869 39.4338 20.8929V7.10594C39.4338 5.21194 38.6338 4.52994 37.3128 4.52994H34.9648V23.4689ZM96.8918 27.2569V0.740944H108.254V4.52794H101.054V11.9139H106.774V15.7019H101.054V23.4659H108.254V27.2539L96.8918 27.2569ZM60.6828 27.2569V0.740944H64.8488V23.4659H71.7048V27.2529L60.6828 27.2569ZM0.424805 27.2569V0.740944H6.71179C11.0298 0.740944 12.8848 2.74794 12.8848 6.84094V7.78794C12.8848 10.5149 12.0518 12.2569 10.1958 13.1289C12.4298 13.9999 13.3008 16.0079 13.3008 18.8099V20.9689C13.3008 25.0599 11.1418 27.2569 6.97583 27.2569H0.424805ZM4.58978 23.4689H6.97583C8.37583 23.4689 9.13483 22.8249 9.13483 20.8559V18.5409C9.13483 16.0789 8.3348 15.3219 6.4458 15.3219H4.58978V23.4689ZM4.58978 11.5379H6.21881C7.77181 11.5379 8.71881 10.8559 8.71881 8.73794V7.26094C8.71881 5.36694 8.07384 4.53394 6.59784 4.53394H4.58978V11.5379Z" fill="url(#paint0_linear_1_7919)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1_7919" x1="0.424805" y1="0.439941" x2="13.2542" y2="51.4514" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#D89143"/>
                                        <stop offset="1" stopColor="#E4AC5F"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div style={{width: 50}}/>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <InputMask
                            mask='+9 (999) 999-99-99'
                            className="inputPhone"
                            value={phone}
                            onChange={({target}) => {
                                const formated = target.value.replace('+8', '+7')
                                setPhone(formated);
                            }}
                            placeholder="Номер телефона"
                        />
                        <div className="description">Вам придет смс кодом авторизации. Ожидание смс может занять некоторое время</div>
                        <button 
                            className="orange-button"
                            ref={phoneRef}
                            disabled={load}
                            onClick={() => {
                                // fetchRegru('get/user?id=6307b2077d32bdade1e1482b', 'GET')
                                // .then(res => {
                                //     if (res.status === 'success') {
                                //         dispatch(ChangeUser({...res.data, uid: res.data._id}))
                                //         setLoad(false);
                                //     } else {
                                //         window.alert('что-то пошло не так: \n'+res.message)
                                //         setLoad(false);
                                //     }
                                // })
                                // .catch((err) => {
                                //     setLoad(false);
                                //     window.alert(err.message);
                                // })
                                setVisble(true)
                            }}    
                            // style={{
                            //     width: 'calc(100% - 30px)',
                            //     alignItems: 'center',
                            //     justifyContent: 'center',
                            //     height: 50,
                            //     margin: 15,
                            //     backgroundColor: load || phone.replace(/\D/g, "").length < 11? '#616161' : '#D89143',
                            //     fontFamily: 'oswald',
                            //     borderRadius: 16,
                            //     fontSize: 18,
                            //     borderWidth: 0
                            // }}
                        >
                            {!load? 'ДАЛЕЕ' : 'ЗАГРУЗКА'}
                        </button>
                    </div>
                </div>
            }
            
        </div>
    )
};