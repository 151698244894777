import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Provider } from 'react-redux';
import {store} from './store';
// import { CookiesProvider } from 'react-cookie';

const firebaseConfig = {
  apiKey: 'AIzaSyBDGPblSMxhlvKl1jBjGqJRQqvwK1_Y5W4',
  authDomain: 'bodyline-256300.firebaseapp.com',
  databaseURL: 'https://bodyline-256300.firebaseio.com',
  projectId: 'bodyline-256300',
  storageBucket: 'bodyline-256300.appspot.com',
  messagingSenderId: '1042487078753',
  appId: '1:1042487078753:web:c3cd197c80cbb74f195f20',
  measurementId: 'G-VKT81RQWMN'
};

firebase.initializeApp({
  ...firebaseConfig,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
