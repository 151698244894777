import './App.css';
import React from 'react';
import { LoginNavigation, MainNavigation } from './Navigations';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeReferral } from './redux/actions/ScreenAction';
import { fetchRegru } from './props';

function App() {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.screen.userData);
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referral = urlParams.get('refferedBY');
    if (referral) {
      dispatch(ChangeReferral(referral));
    }
    console.log('referred by', referral)
  }, []);

  if (typeof userId?.uid === 'string' && userId?.uid?.length > 0) {
    return (
      <MainNavigation />
    );
  }

  return (
    <LoginNavigation />
  );
}

export default App;
