import React from "react";
import { useNavigate } from "react-router-dom";
import '../App.css';
import { fetchRegru, mainColor } from "../props";

const axios = require('axios').default;

export const SubscriptionScreen = ({uid}) => {
    const [loading, setLoading] = React.useState(true);
    const [dotindex, setIndex] = React.useState(0);
    const [confirm, setConfirm] = React.useState(false);
    const [activeSub, setSubs] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [touch, setTouch] = React.useState(0);
    const navigate = useNavigate();

    React.useEffect(() => {
        fetchRegru('check/usersubscribes?id='+uid, 'GET')
        .then((res) => {
            if (res.status === 'success') {
                setSubs(res.data);
            } else {
                window.alert('Проверка подписок прошла неудачно попробуйте перезагрузить страницу');
            }
            setLoading(false);
        })
        .catch(() => window.alert('Проверка подписок прошла неудачно попробуйте перезагрузить страницу'))
    },[]);

    React.useEffect(() => {
        if (!loading) {
            const slider = document.getElementById('slider');
            slider?.addEventListener('scroll', scroll => {
                const index = Math.round(slider.scrollLeft/slider?.getBoundingClientRect().width);
                setIndex(index);
            });
        }
    },[loading])

    React.useEffect(() => {
        const slider = document.getElementById('slider');
        slider?.scrollBy({
            left: dotindex*slider?.getBoundingClientRect().width-slider.scrollLeft,
            behavior: 'smooth'
        })
    },[dotindex]);

    if (loading) {
        return;
    }

    return(
        <div
            className="screen-view"
        >
            <div
                style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: 15,
                    paddingRight: 15,
                }}
            >
                <div style={{width: 27, color: 'white'}}></div>
                <svg width="109" height="28" viewBox="0 0 109 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.4648 20.8929V7.10594C15.4648 2.86394 17.6998 0.439941 21.7898 0.439941C25.8798 0.439941 28.1148 2.86394 28.1148 7.10594V20.8929C28.1148 25.1349 25.8808 27.5589 21.7898 27.5589C17.6988 27.5589 15.4648 25.1349 15.4648 20.8929ZM19.6298 6.84094V21.1579C19.6298 23.0519 20.4628 23.7709 21.7888 23.7709C23.1148 23.7709 23.9478 23.0509 23.9478 21.1579V6.84094C23.9478 4.94694 23.1148 4.22794 21.7888 4.22794C20.4628 4.22794 19.6298 4.94694 19.6298 6.84094ZM49.5888 27.2569V18.4699L44.3298 0.740944H48.6858L51.8668 12.8229L55.0478 0.740944H59.0248L53.7608 18.4669V27.2539L49.5888 27.2569ZM89.6228 27.2569L84.4338 8.05694V27.2569H80.6838V0.740944H85.9108L90.1908 16.6099V0.740944H93.9028V27.2539L89.6228 27.2569ZM73.5228 27.2569V0.740944H77.6888V27.2539L73.5228 27.2569ZM30.7988 27.2569V0.740944H37.3888C41.5558 0.740944 43.5998 3.05094 43.5998 7.29294V20.7009C43.5998 24.9429 41.5548 27.2539 37.3888 27.2539L30.7988 27.2569ZM34.9648 23.4689H37.3128C38.6388 23.4689 39.4338 22.7869 39.4338 20.8929V7.10594C39.4338 5.21194 38.6338 4.52994 37.3128 4.52994H34.9648V23.4689ZM96.8918 27.2569V0.740944H108.254V4.52794H101.054V11.9139H106.774V15.7019H101.054V23.4659H108.254V27.2539L96.8918 27.2569ZM60.6828 27.2569V0.740944H64.8488V23.4659H71.7048V27.2529L60.6828 27.2569ZM0.424805 27.2569V0.740944H6.71179C11.0298 0.740944 12.8848 2.74794 12.8848 6.84094V7.78794C12.8848 10.5149 12.0518 12.2569 10.1958 13.1289C12.4298 13.9999 13.3008 16.0079 13.3008 18.8099V20.9689C13.3008 25.0599 11.1418 27.2569 6.97583 27.2569H0.424805ZM4.58978 23.4689H6.97583C8.37583 23.4689 9.13483 22.8249 9.13483 20.8559V18.5409C9.13483 16.0789 8.3348 15.3219 6.4458 15.3219H4.58978V23.4689ZM4.58978 11.5379H6.21881C7.77181 11.5379 8.71881 10.8559 8.71881 8.73794V7.26094C8.71881 5.36694 8.07384 4.53394 6.59784 4.53394H4.58978V11.5379Z" fill="url(#paint0_linear_1_7919)"/>
                    <defs>
                        <linearGradient id="paint0_linear_1_7919" x1="0.424805" y1="0.439941" x2="13.2542" y2="51.4514" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#D89143"/>
                            <stop offset="1" stopColor="#E4AC5F"/>
                        </linearGradient>
                    </defs>
                </svg>
                <div onClick={() => {window.open("https://bodylineykt.ru/telegram/", "_blank")}}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.999 27.683C12.2012 27.6879 10.4203 27.3363 8.75926 26.6485C7.09824 25.9607 5.5901 24.9504 4.32201 23.6761C3.04747 22.4081 2.03701 20.8998 1.34905 19.2388C0.661086 17.5778 0.309291 15.7969 0.314012 13.9991C0.309291 12.2012 0.661086 10.4202 1.34905 8.7592C2.03701 7.09818 3.04747 5.59008 4.32201 4.32207C5.59002 3.04753 7.09812 2.03701 8.75914 1.34905C10.4202 0.661086 12.2012 0.309291 13.999 0.314012C15.7969 0.309291 17.5778 0.661086 19.2388 1.34905C20.8998 2.03701 22.408 3.04753 23.676 4.32207C24.9504 5.59016 25.9606 7.0983 26.6484 8.75932C27.3362 10.4203 27.6878 12.2013 27.683 13.9991C27.6878 15.7969 27.3362 17.5778 26.6484 19.2388C25.9606 20.8998 24.9504 22.408 23.676 23.6761C22.4079 24.9504 20.8997 25.9607 19.2387 26.6485C17.5777 27.3363 15.7968 27.6879 13.999 27.683ZM13.817 18.3311L17.237 20.8521C17.4028 20.9561 17.5925 21.0159 17.788 21.026C18.127 21.026 18.363 20.766 18.473 20.273L20.72 9.68901C20.7679 9.53671 20.7815 9.37576 20.7599 9.21758C20.7383 9.05939 20.682 8.90797 20.595 8.77409C20.5354 8.70169 20.4599 8.6439 20.3744 8.60539C20.2889 8.56689 20.1957 8.54867 20.102 8.55205C19.9886 8.55341 19.8763 8.57439 19.77 8.61406L6.57597 13.7021C6.12897 13.8771 5.87598 14.102 5.88298 14.325C5.88898 14.513 6.08297 14.6821 6.41997 14.7891L9.799 15.8421L17.634 10.9091C17.7403 10.8334 17.8658 10.7893 17.996 10.782C18.078 10.782 18.138 10.809 18.155 10.855C18.172 10.901 18.14 10.9751 18.055 11.0461L11.714 16.7741L11.471 20.2551C11.6041 20.2558 11.7356 20.2258 11.8552 20.1675C11.9749 20.1093 12.0795 20.0242 12.161 19.919L13.811 18.33L13.817 18.3311Z" fill="url(#paint0_linear_1_8530)"/>
                        <defs>
                            <linearGradient id="paint0_linear_1_8530" x1="13.9985" y1="0.313965" x2="13.9985" y2="27.6831" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#D89143"/>
                                <stop offset="1" stopColor="#E4AC5F"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
            <div className="slider__container">
                <div 
                    className="slider__items" 
                    id="slider"
                    onTouchStart={(event) => {
                        setTouch(event.currentTarget.scrollLeft);
                    }}
                    onTouchEnd={(event) => {
                        const diff = Math.abs(touch - event.currentTarget.scrollLeft);
                        if (diff > 60) {
                            if (touch - event.currentTarget.scrollLeft > 0) {
                                event.currentTarget?.scrollBy({
                                    left: (dotindex-1)*event.currentTarget?.getBoundingClientRect().width-event.currentTarget.scrollLeft,
                                    behavior: 'smooth'
                                })
                            } else {
                                event.currentTarget?.scrollBy({
                                    left: (dotindex+1)*event.currentTarget?.getBoundingClientRect().width-event.currentTarget.scrollLeft,
                                    behavior: 'smooth'
                                })
                            }
                        } else {
                            event.currentTarget.scrollBy({
                                left: dotindex*event.currentTarget.getBoundingClientRect().width-event.currentTarget.scrollLeft,
                                behavior: 'smooth'
                            })
                        }
                    }}
                >
                    <div className="slider__item1" style={{aspectRatio: 375/450, width: '100%'}}/>
                    <div className="slider__item2" style={{aspectRatio: 375/450, width: '100%'}}/>
                    <div className="slider__item3" style={{aspectRatio: 375/450, width: '100%'}}/>
                </div>
                <div className="dots-box">
                    <div
                        onClick={() => {
                            const slider = document.getElementById('slider');
                            slider.scrollBy({
                                left: -slider.scrollLeft,
                                behavior: 'smooth'
                            });
                        }} 
                        className="dot" style={{backgroundColor: dotindex === 0? 'white' : '#35322F'}}
                    />
                    <div 
                        onClick={() => {
                            const slider = document.getElementById('slider');
                            slider.scrollBy({
                                left: slider.scrollLeft+(slider.getBoundingClientRect().width-slider.scrollLeft),
                                behavior: 'smooth'
                            });
                        }} 
                        className="dot" style={{backgroundColor: dotindex === 1? 'white' : '#35322F'}}
                    />
                    <div 
                        onClick={() => {
                            const slider = document.getElementById('slider');
                            slider.scrollBy({
                                left: slider.getBoundingClientRect().width*2,
                                behavior: 'smooth'
                            });
                        }} 
                        className="dot" style={{backgroundColor: dotindex === 2? 'white' : '#35322F'}}
                    />
                </div>
            </div>
            {
                activeSub?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: 80,
                        paddingTop: 30,
                        overflow: 'scroll'
                    }}
                >
                    <button 
                        className="orange-button" 
                        onClick={() => {
                            let userAgent = window.navigator.userAgent.replace(';',' ').replace('(',' ').replace(')',' ')
                            const arr = userAgent.split(' ');
                            let type = '';
                            arr.forEach((item, index) => {
                                if (item === 'Android') {
                                    type = 'Android'
                                    window.open("https://play.google.com/store/apps/details?id=com.bodyline", "_blank")
                                } 
                                if (item === 'iPhone') {
                                    type = 'iPhone'
                                    window.open("https://apps.apple.com/kg/app/bodylineykt/id1502373306", "_blank")
                                }
                                if (item === 'iPad') {
                                    type = 'iPad'
                                    window.open("https://apps.apple.com/kg/app/bodylineykt/id1502373306", "_blank")
                                }
                            })
                            if (type === '') {
                                window.open("https://bodylineykt.ru/", "_blank")
                            } 
                        }}
                    >ПЕРЕЙТИ В ПРИЛОЖЕНИЕ</button>
                </div>
                :
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: 80
                    }}
                >
                    <button 
                        className="orange-button"
                        disabled={!confirm}
                        onClick={() => {
                            if (confirm) {
                                const widget = new window.cp.CloudPayments();
                                widget.pay(
                                    'charge',
                                    { //options
                                        publicId: 'pk_4766ff888132870993fa2c870f349', //id из личного кабинета
                                        description: 'Подписка Bodyline', //назначение
                                        amount: 299, //сумма
                                        currency: 'RUB', //валюта
                                        accountId: uid, //идентификатор плательщика (необязательно)
                                        skin: "modern", //дизайн виджета (необязательно)
                                        requireEmail: true,
                                        data: {
                                            CloudPayments: {
                                                recurrent: {
                                                    interval: 'Month',
                                                    period: 1, 
                                                }
                                            }
                                        }
                                    },
                                    {
                                        onSuccess: function (options) { // success
                                            fetchRegru('get/usersubscription?id='+uid, 'GET')
                                            .then((res) => {
                                                if (res.status === 'success') {
                                                    setSubs(res.data);
                                                    // navigate('/marathon')
                                                } else {
                                                    window.alert('Проверка подписок прошла неудачно попробуйте перезагрузить страницу');
                                                }
                                            })
                                            .catch(() => window.alert('Проверка подписок прошла неудачно попробуйте перезагрузить страницу'))
                                            //действие при успешной оплате
                                        },
                                        onFail: function (reason, options) { // fail
                                            //действие при неуспешной оплате
                                        },
                                        onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                                            //например вызов вашей аналитики Facebook Pixel
                                        }
                                    }
                                )
                            } else {
                                window.alert('Нажмите галочку, для совершения покупки');
                            }
                        }}
                    >
                        ПОДПИСАТЬСЯ ЗА 299 ₽/МЕСЯЦ
                    </button>
                    <div style={{display: 'flex', flexDirection: 'row', padding: 15}}>
                        <div style={{width: 25, marginRight: 10}} onClick={() => {setConfirm(!confirm)}}>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="25" height="25" rx="5" fill="url(#paint0_linear_164_130)"/>
                                {
                                    confirm?
                                        <path d="M8 13.5294L10.3478 16L17 9" stroke="white" strokeWidth="2"/>
                                        :null
                                }
                                <defs>
                                    <linearGradient id="paint0_linear_164_130" x1="0" y1="0" x2="25" y2="0" gradientUnits="userSpaceOnUse">
                                        <stop stopColor={confirm?"#D89143":'#616161'}/>
                                        <stop offset="1" stopColor={confirm?"#E4AC5F":'#616161'}/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div
                            style={{color: '#5A5650'}}
                        >
                            Оформляя подписку я даю согласие на обработку моих персональных данных, а также на последующие автоматические списания. {'\n'}Совершая покупку, я принимаю ваши Условия использования и Политику конфиденциальности. 
                        </div>
                    </div>
                    <div
                        style={{color: '#5A5650', marginLeft: 15, marginRight: 15, textAlign: 'justify'}}
                    >
                        Пользователи, оформившие подписку, получают доступ к функциям приложения, отмеченным выше. 
                        Обновление подписки и соответствующая авторизация платежа будут автоматически активироваться 
                        происходить по цене, указанной в учетной записи Google Play, за 24 часа до окончания выбранного 
                        периода подписки до тех пор, пока вы ее не отмените. Платеж будет списан однократно за каждую 
                        подписку в первый день нового срока подписки через учетную запись Google Play. 
                        Управлять подпиской можно в настройках учетной записи. Для оплаты вы можете использовать баллы магазина. 
                        Однако для оформления подписки у вас должен быть действительный метод оплаты. 
                        Совершая покупку, вы принимаете наши Условия использования и Политику конфиденциальности.
                    </div>
                </div>
            }
            
        </div>
    )
}