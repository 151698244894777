import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchRegru, getCurrentDayIndex } from "../props";

const fakeData = {
    firstName: 'asdas',
    lastName: 'asda',
    weights: [{
        date: new Date,
        reason: '',
        old: [],
        value: 65.6
    }],
    measures: [],
    days: [],
    coach: '',
    weightDiff: -1.2,
    weightDiffSum: -2.4,
    birthdate: new Date(),
    target: 0,
    targetWeight: 60,
    height: 160,
    sex: 'f',
    contacts: {
        phone: '',
        webSite: '',
        whatsapp: '',
        telegram: ''
    },
    _id: 'asdgsdgs',
    photoUrl: 'https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80',
    subStat: true,
    nutritionalNorms: {
        proteins: 60,
        fats: 40,
        carbohydrates: 80
    }
}

export default ({ uid }) => {
    const [group, setGroup] = React.useState([]);
    const [skip, setSkip] = React.useState()

    React.useEffect(() => {
        let groupid = '652fec815b0fb7159ba2838d'

        const ids = [
            '6307add47d32bdade1e0805d',
            '652d30577eca5f1a6b07ebdd',
            '6307add77d32bdade1e08137',
            '6307a81c7d32bdade1df6577',
            '6307a7727d32bdade1df4375',
            '64e03a46c41e0d445d1df8db',
            '6307b5517d32bdade1e1f32b',
            '652e654afe54a52ab6db1b4a',
            '652e655efe54a52ab6db1b58',
            '64e03ae7c41e0d445d1e7375',
            '6372e43694e8047b4813f751',
            '6307aff27d32bdade1e0e68b',
            '65291b2147242bbeb32a2966',
            '6307a5e17d32bdade1def2c8'
        ]

        const groups = [
            '652fec8c5b0fb7159ba2838f',
            '652fec9b5b0fb7159ba28391',
            '652feca55b0fb7159ba28393',
            '652fecb55b0fb7159ba28395',
            '652fecc35b0fb7159ba28397',
            '652fec815b0fb7159ba2838d',
            '652e5c53b59df57e7eb46490',
            '652fecd65b0fb7159ba28399',
            '652fecef5b0fb7159ba2839b',
            '652fecfb5b0fb7159ba2839d',
            '652fed075b0fb7159ba2839f',
            '652fed1a5b0fb7159ba283a1',
            '652fed265b0fb7159ba283a3',
            '6530df74df97dc820a8a0044'
        ]

        ids.forEach((item, index) => {
            if (item === uid) {
                groupid = groups[index]
            }
        })

        if (groupid) {
            fetchRegru(`get/group?id=${groupid}&dayIndex=${getCurrentDayIndex()}&date=${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()}&coachId=6433eed5451cd9cda0204eeb&all=${true}&skip=0`, 'GET')
                .then(res => {
                    console.log(res);
                    if (res.status === 'success') {
                        setGroup(res.data)
                    }
                })
        }
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 24,
                paddingLeft: 16,
                paddingRight: 16,
                gap: 16,
                overflow: 'scroll',
                marginBottom: 80
            }}
        >
            {
                group.map((item) => {
                    return (
                        <User item={item} />
                    )
                })
            }
        </div>
    )
}

const User = ({ item }) => {
    const [show, setShow] = React.useState(false);
    const navigate = useNavigate();

    const weight = item.currentWeight ?? 75;

    const calories = Math.round(weight * [0.75, 1, 1.25][item.target] * 30)

    const defaultProts = Math.round(weight * 2);

    const defaultFats = Math.round(weight);

    const defaultCarbs = Math.round((calories - defaultProts * 4 - defaultFats * 8) / 4);

    const day = item.days[0]

    const getNutries = (time, key) => {
        let sum = 0;
        if (day) {
            const arr = day[time]
            arr?.forEach(item => {
                sum = sum + item[key]
            })
        }
        return Math.round(sum)
    }

    const getEatCals = () => {
        let sum = 0;
        if (day) {
            let times = ['breakfast', 'secondBreakfast', 'lunch', 'afternoonTea', 'dinner']
            times.forEach(time => {
                const arr = day[time]
                arr?.forEach(item => {
                    sum = sum + item.cal
                })
            })
        }
        return Math.round(sum)
    }

    const getSummary = (key) => {
        let sum = 0;
        if (day) {
            let times = ['breakfast', 'secondBreakfast', 'lunch', 'afternoonTea', 'dinner']
            times.forEach(time => {
                const arr = day[time]
                arr?.forEach(item => {
                    sum = sum + item[key]
                })
            })
        }
        return Math.round(sum)
    }

    const measures = item.measures.length > 0 ? item.measures[item.measures.length - 1] : null

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#1A1A1A',
                borderRadius: 24,
                padding: 16,
                gap: 8
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 16
                }}
            >
                <img
                    src={item.photoUrl}
                    style={{
                        borderRadius: 100,
                        height: 44,
                        width: 44,
                        backgroundColor: 'gray'
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1
                    }}
                >
                    <div style={{ color: 'white', fontSize: 16 }}>{item.firstName} {item.lastName}</div>
                    <div style={{ color: typeof item.weightDiff === 'string' || item.weightDiff > 0? '#FF1C45' : '#1BC760', fontSize: 12 }}>{item.weightDiff > 0? '+' : ''}{typeof item.weightDiff === 'string' ? item.weightDiff : Math.round(item.weightDiff * 10) / 10} {typeof item.weightDiff === 'string' ? '' : 'кг'}</div>
                    <div style={{ color: '#8A8B8E', fontSize: 12 }}>Цель: {item.target === 0 ? 'Похудение' : item.target === 1 ? 'Тонус' : 'Набор'}</div>
                </div>
                <div
                    onClick={() => setShow(!show)}
                    style={{
                        transform: show ? undefined : 'rotate(180deg)'
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 15L12 9L5 15" stroke="#DE9F51" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
            <div
                style={{ height: 1, backgroundColor: '#2C2D2F', marginTop: 4, marginBottom: 4 }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: 'row',
                    gap: 8
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 18,
                        backgroundColor: '#2C2D2F',
                        justifyContent: 'space-between',
                        padding: 12,
                        flex: 1,
                        height: 46
                    }}
                >
                    <div
                        style={{ color: '#8A8B8E', fontSize: 12 }}
                    >Сожжено Ккал</div>
                    <div
                        style={{
                            fontFamily: 'Oswald',
                            fontSize: 22,
                            color: 'white'
                        }}
                    >
                        {Math.round(day?.burnedCals ?? 0)}/500
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 18,
                        backgroundColor: '#2C2D2F',
                        justifyContent: 'space-between',
                        padding: 12,
                        flex: 1,
                        height: 46
                    }}
                >
                    <div
                        style={{ color: '#8A8B8E', fontSize: 12 }}
                    >Потреблено Ккал</div>
                    <div
                        style={{
                            fontFamily: 'Oswald',
                            fontSize: 22,
                            color: 'white'
                        }}
                    >
                        {getEatCals()}/{calories}
                    </div>
                </div>
            </div>
            {
                show ?
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8
                        }}
                    >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderRadius: 18,
                            backgroundColor: '#2C2D2F',
                            padding: 12,
                            height: 50,
                            gap: 8
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                                color: '#8A8B8E',
                                fontSize: 12
                            }}
                        >
                            <div
                                style={{
                                    color: 'white',
                                    fontSize: 24,
                                    fontFamily: 'Oswald'
                                }}
                            >{item.targetWeight} кг</div>
                            Цель
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                                color: '#8A8B8E',
                                fontSize: 12
                            }}
                        >
                            <div
                                style={{
                                    color: 'white',
                                    fontSize: 24,
                                    fontFamily: 'Oswald'
                                }}
                            >{weight} кг</div>
                            Текущий вес
                        </div>
                    </div>
                        {
                            measures ?
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 12,
                                        paddingRight: 12,
                                        gap: 12,
                                        backgroundColor: '#2C2D2F',
                                        borderRadius: 18
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: 14,
                                            color: '#8A8B8E'
                                        }}
                                    >Замеры тела - {new Date(measures.date).toLocaleDateString('ru-RU')}</div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 8
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                gap: 8,
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div style={{ color: '#C5C5C5' }}>Руки</div>
                                            <div style={{ color: '#C5C5C5' }}>{measures.hands}</div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                gap: 8,
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div style={{ color: '#C5C5C5' }}>Живот</div>
                                            <div style={{ color: '#C5C5C5' }}>{measures.stomach}</div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                gap: 8,
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div style={{ color: '#C5C5C5' }}>Ягодицы</div>
                                            <div style={{ color: '#C5C5C5' }}>{measures.buttocks}</div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                gap: 8,
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div style={{ color: '#C5C5C5' }}>Бёдра</div>
                                            <div style={{ color: '#C5C5C5' }}>{measures.hips}</div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div
                            style={{
                                fontFamily: 'Oswald',
                                fontSize: 18,
                                color: 'white'
                            }}
                        >
                            Всего
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                borderRadius: 18,
                                backgroundColor: '#2C2D2F',
                                padding: 12,
                                height: 50,
                                gap: 8
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Ккал</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%',
                                        display: 'flex'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getSummary('cal') / Math.round(calories) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    ></div>
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getSummary('cal')}/{Math.round(calories)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Белки</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getSummary('p') / Math.round(defaultProts) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getSummary('p')}/{defaultProts}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Углеводы</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getSummary('c') / Math.round(defaultCarbs) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getSummary('c')}/{defaultCarbs}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Жиры</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getSummary('f') / Math.round(defaultFats) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getSummary('f')}/{defaultFats}</div>
                            </div>
                        </div>
                        <div
                            style={{
                                fontFamily: 'Oswald',
                                fontSize: 18,
                                color: 'white'
                            }}
                        >
                            Завтрак
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                borderRadius: 18,
                                backgroundColor: '#2C2D2F',
                                padding: 12,
                                height: 50,
                                gap: 8
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Ккал</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%',
                                        display: 'flex'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('breakfast', 'cal') / Math.round(calories * 0.25) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    ></div>
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('breakfast', 'cal')}/{Math.round(calories * 0.25)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Белки</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('breakfast', 'p') / Math.round(defaultProts * 0.25) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('breakfast', 'p')}/{defaultProts * 0.25}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Углеводы</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('breakfast', 'c') / Math.round(defaultCarbs * 0.25) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('breakfast', 'c')}/{defaultCarbs * 0.25}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Жиры</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('breakfast', 'f') / Math.round(defaultFats * 0.25) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('breakfast', 'f')}/{defaultFats * 0.25}</div>
                            </div>
                        </div>
                        <div
                            style={{
                                fontFamily: 'Oswald',
                                fontSize: 18,
                                color: 'white'
                            }}
                        >
                            Перекус
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                borderRadius: 18,
                                backgroundColor: '#2C2D2F',
                                padding: 12,
                                height: 50,
                                gap: 8
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Ккал</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('secondBreakfast', 'cal') / Math.round(calories * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('secondBreakfast', 'cal')}/{Math.round(calories * 0.175)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Белки</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('secondBreakfast', 'p') / Math.round(defaultProts * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('secondBreakfast', 'p')}/{Math.round(defaultProts * 0.175)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Углеводы</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('secondBreakfast', 'c') / Math.round(defaultCarbs * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('secondBreakfast', 'c')}/{Math.round(defaultCarbs * 0.175)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Жиры</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('secondBreakfast', 'f') / Math.round(defaultFats * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('secondBreakfast', 'f')}/{Math.round(defaultFats * 0.175)}</div>
                            </div>
                        </div>
                        <div
                            style={{
                                fontFamily: 'Oswald',
                                fontSize: 18,
                                color: 'white'
                            }}
                        >
                            Обед
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                borderRadius: 18,
                                backgroundColor: '#2C2D2F',
                                padding: 12,
                                height: 50,
                                gap: 8
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Ккал</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('lunch', 'cal') / Math.round(calories * 0.25) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('lunch', 'cal')}/{Math.round(calories * 0.25)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Белки</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('lunch', 'p') / Math.round(defaultProts * 0.25) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('lunch', 'p')}/{defaultProts * 0.25}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Углеводы</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('lunch', 'c') / Math.round(defaultCarbs * 0.25) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('lunch', 'c')}/{defaultCarbs * 0.25}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Жиры</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('lunch', 'f') / Math.round(defaultFats * 0.25) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('lunch', 'f')}/{defaultFats * 0.25}</div>
                            </div>
                        </div>
                        <div
                            style={{
                                fontFamily: 'Oswald',
                                fontSize: 18,
                                color: 'white'
                            }}
                        >
                            Полдник
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                borderRadius: 18,
                                backgroundColor: '#2C2D2F',
                                padding: 12,
                                height: 50,
                                gap: 8
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Ккал</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('afternoonTea', 'cal') / Math.round(calories * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('afternoonTea', 'cal')}/{Math.round(calories * 0.175)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Белки</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('afternoonTea', 'p') / Math.round(defaultProts * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('afternoonTea', 'p')}/{Math.round(defaultProts * 0.175)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Углеводы</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('afternoonTea', 'c') / Math.round(defaultCarbs * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('afternoonTea', 'c')}/{Math.round(defaultCarbs * 0.175)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Жиры</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('afternoonTea', 'f') / Math.round(defaultFats * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('afternoonTea', 'f')}/{Math.round(defaultFats * 0.175)}</div>
                            </div>
                        </div>
                        <div
                            style={{
                                fontFamily: 'Oswald',
                                fontSize: 18,
                                color: 'white'
                            }}
                        >
                            Ужин
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                borderRadius: 18,
                                backgroundColor: '#2C2D2F',
                                padding: 12,
                                height: 50,
                                gap: 8
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Ккал</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('dinner', 'cal') / Math.round(calories * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('dinner', 'cal')}/{Math.round(calories * 0.175)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Белки</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('dinner', 'p') / Math.round(defaultProts * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('dinner', 'p')}/{Math.round(defaultProts * 0.175)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Углеводы</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('dinner', 'c') / Math.round(defaultCarbs * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('dinner', 'c')}/{Math.round(defaultCarbs * 0.175)}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                }}
                            >
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >Жиры</div>
                                <div
                                    style={{
                                        backgroundColor: '#3C3C3E',
                                        height: 3,
                                        borderRadius: 3,
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: 3,
                                            borderRadius: 3,
                                            width: Math.round(getNutries('dinner', 'f') / Math.round(defaultFats * 0.175) * 100) + '%',
                                            backgroundColor: "#DE9F51",
                                            maxWidth: '100%',
                                        }}
                                    />
                                </div>
                                <div
                                    style={{ color: '#8A8B8E', fontSize: 12 }}
                                >{getNutries('dinner', 'f')}/{Math.round(defaultFats * 0.175)}</div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}