import {CHANGE_REFERRAL, CHANGE_SCREEN, CHANGE_USER} from '../types';

export const ChangeScreen = (value) => ({
    type: CHANGE_SCREEN,
    changeScreen: value   
})

export const ChangeUser = (value) => ({
    type: CHANGE_USER,
    changeUser: value   
})

export const ChangeReferral = (value) => ({
    type: CHANGE_REFERRAL,
    referral: value   
})