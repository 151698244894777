import axios from "axios";
import moment from 'moment';

export const black = '#000000';
export const mainColor = '#DE9F51';
export const grey = '#7A7772';

const url = 'https://api.bodylineapp.com/';

export const fetchRegru = async (path, method, body) => {
    if (method === 'GET') {
        return axios.get(url + path, {
            headers: {
                'Access': 'application/json',
                'Content-Type': 'application/json',
                'blcommon': 'Ym9keWxpbmUtY29tbW9uOmNvbW1vblVzZXI=',
                'Access-Control-Allow-Origin': '*',
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
            }
        }).then(res => res.data)
            .catch(e => ({
                status: 'error',
                message: e.message
            }))
    }
    if (method === 'POST') {
        return axios.post(url + path, {
            ...body,
        }, {
            headers: {
                'Access': 'application/json',
                'Content-Type': 'application/json',
                'blcommon': 'Ym9keWxpbmUtY29tbW9uOmNvbW1vblVzZXI=',
                'Access-Control-Allow-Origin': '*',
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
            }
        }).then(res => res.data)
            .catch(e => ({
                status: 'error',
                message: e.message
            }))
    }
    return ({
        status: 'error',
        message: 'no method'
    })
    // const result = axios(url+path, {
    //     method: method,
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json',
    //         'blcommon': 'Ym9keWxpbmUtY29tbW9uOmNvbW1vblVzZXI='
    //     },
    //     body: JSON.stringify(body)
    // })
    // .then(res => {
    //   return res.json()
    // })
    // .catch((e) => ({
    //     status: 'error',
    //     message: e.message
    // }))
    // return result;
}

export function getTrainingStartDate() {
    return moment('08.08.2021', 'DD.MM.YYYY'); // 09.08.2021
}

export function getCurrentDayIndex() {
    return moment().diff(getTrainingStartDate(), 'days');
}