import {CHANGE_REFERRAL, CHANGE_SCREEN, CHANGE_USER} from '../types';

const initial_state = {
    screen: false,
    userData: null,
    referral: null,
}

export default function ScreenReducer (state = initial_state, action) {
    switch (action.type) {
        case CHANGE_SCREEN: {
            return {
                ...state,
                screen: action.changeScreen
            }
        }
        case CHANGE_USER: {
            return {
                ...state,
                userData: action.changeUser
            }
        }
        case CHANGE_REFERRAL: {
            return {
                ...state,
                referral: action.referral
            }
        }
        default:
            return state;
        
    }
}